import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AsyncStateOverlayModule,
  EsprioSharedModule,
  NavBarModule,
  RelativeAccountInterceptor,
  RelativeAccountModule,
  SharedModule
} from 'ngx-esprio-shared';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppInitializer } from './app.initializer';
import { routes } from './app.routes';
import { ViewsModule } from './views/views.module';
import { AppRelativeAccountModule } from 'projects/nutrition/src/app/views/relative-account/app-relative-account.module';
import { CoreStoreModule } from './store/core-store.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top',
    }),
    RelativeAccountModule,
    AppRelativeAccountModule,
    EsprioSharedModule.forRoot({
      apiBaseUrl: environment.apiBaseUrl,
      environment: environment.environment,
      nutritionMonitoringApp: environment.nutritionMonitoringApp,
      disabledAppFeatures: environment.disabledAppFeatures,
    }),
    CoreStoreModule,
    ViewsModule,
    NavBarModule,
    AsyncStateOverlayModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (translateService: TranslateService) => {
        return () => AppInitializer(translateService);
      },
      multi: true,
      deps: [TranslateService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RelativeAccountInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
