<div class="card">
  <div class="card-body">
    <h4 class="card-title mb-3">
      {{ 'dashboard.on_demand_survey.head' | translate }}
    </h4>

    <p class="mb-3">
      {{ 'dashboard.on_demand_survey.body' | translate }}
    </p>

    <div class="text-center mb-5">
      <img src="assets/images/QuestionnaireGroupFirst.svg" alt="Survey Image" />
    </div>

    <button
      class="btn btn-primary btn-lg w-100 mb-2"
      (click)="handleStartSurveyClick()"
    >
      {{ 'dashboard.on_demand_survey.button' | translate }}
    </button>
  </div>
</div>
