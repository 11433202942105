<div *ngIf="selectedLandingPage$ | async as selectedLandingPage">
  <div class="top-section position-relative d-flex flex-row mt-5">
    <div class="container-xxl d-flex flex-row align-items-center justify-content-between">
      
      <div class="header-section d-flex flex-column align-items-start">
        <h1 class="text headline" [innerHTML]="'landing_page.top_section.webk_headline_' + selectedLandingPage | translate "></h1>
        <h4 class="text sub-headline" [innerHTML]="'landing_page.top_section.webk_sub_headline' | translate "></h4>
        <div class="features d-flex flex-column">
          <div class="feature-item d-flex align-items-center">
            <img class="px-1" src="assets/images/check-solid.svg" alt="check"/>
            <span class="text">{{ 'landing_page.top_section.webk_free' | translate}}</span>
          </div>
          <div class="feature-item d-flex align-items-center">
            <img class="px-1" src="assets/images/check-solid.svg" alt="check"/>
            <span class="text">{{ 'landing_page.top_section.webk_scientific' | translate}}</span>
          </div>
          <div class="feature-item d-flex align-items-center">
            <img class="px-1" src="assets/images/check-solid.svg" alt="check"/>
            <span class="text">{{ 'landing_page.top_section.webk_safe' | translate}}</span>
          </div>
        </div>
        <div class="d-flex flex-column my-4">
          <button class="btn btn-lg btn-primary" *ngIf="(selectedLandingPage$ | async) === landingpage.User" (click)="handleStartMedipolisLane()">
            {{ 'landing_page.webk_fastlane' | translate }}
          </button>
          <ng-container *ngIf="(selectedLandingPage$ | async) === landingpage.User">
            <button class="btn btn-link pt-2" (click)="handleLoginClick()">
              {{ 'landing_page.webk_sign_in' | translate }}
            </button>
          </ng-container>
        </div>
      </div>
      
      <div class="image-section">
        <img src="assets/images/Slime2.png" alt="Slime Image" class="img-fluid"/>
      </div>
    </div>
  </div>  
  <div class="container-fluid py-5 p-4" style="background-color: #F0FAEB;">
    <div class="container my-4">
      <h1 class="text text-center">{{ 'landing_page.webk_features.headline1' | translate }}</h1>
      <h4 class=" text text-center sub-headline">
        {{ 'landing_page.webk_features.sub_headline1' | translate }}
      </h4>
      <div class="row mt-4">
        <div class="col-md-4 feature-box">
          <img class="me-2 mb-1" src="assets/images/eye-solid.svg" alt="Echtzeit Monitoring"/>
          <span class="text" style="font-size: 20px;"><b>{{ 'landing_page.webk_features.feature1_headline' | translate }}</b></span>
          <p class="text justified-text">
            {{ 'landing_page.webk_features.feature1' | translate }}          
          </p>
        </div>
        <div class="col-md-4 feature-box">
          <img class="me-2 mb-1" src="assets/images/diagram-project-solid.svg" alt="Angepasste Empfehlungen"/>
          <span class="text" style="font-size: 20px;"><b>{{ 'landing_page.webk_features.feature2_headline' | translate }}</b></span>
          <p class="text justified-text">
            {{ 'landing_page.webk_features.feature2' | translate }}          
          </p>
        </div>
        <div class="col-md-4 feature-box">
          <img class="me-2 mb-1" src="assets/images/hospital-user-solid.svg" alt="Lückenlose Versorgung" />
          <span class="text" style="font-size: 20px;"><b>{{ 'landing_page.webk_features.feature3_headline' | translate }}</b></span>
          <p class="text justified-text">
            {{ 'landing_page.webk_features.feature3' | translate }}          
          </p>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="small-info">
        <div class="container-xxl d-md-flex flex-row justify-content-between">
          <div
            class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
          >
            <img class="align-self-center" src="assets/images/users-solid.svg" />
            <h3 class="text-white">
              {{ 'landing_page.small_info.headline1_' + selectedLandingPage | translate }}
            </h3>
            <p class="text-white">
              {{ 'landing_page.small_info.text1' | translate }}
            </p>
          </div>
          <div
            class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
          >
            <img class="align-self-center" src="assets/images/graduation-cap-solid.svg" />
            <h3 class="text-white">
              {{ 'landing_page.small_info.headline2_webk' | translate }}
            </h3>
            <p class="text-white">
              {{ 'landing_page.small_info.text2_webk' | translate }}
            </p>
          </div>
          <div
            class="info-container d-flex flex-column justify-content-center text-center h-100 col-3"
          >
            <img class="align-self-center" src="assets/images/shield-solid.svg" />
            <h3 class="text-white">
              {{ 'landing_page.small_info.headline3_webk'| translate }}
            </h3>
            <p class="text-white">
              {{ 'landing_page.small_info.text3_webk' | translate }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="container-fluid my-5 p-4">
    <div class="container my-4">
      <h1 class="text text-center">{{ 'landing_page.webk_features.headline2' | translate }}</h1>
      <h4 class=" text text-center sub-headline">
        {{ 'landing_page.webk_features.sub_headline2' | translate }}      
      </h4>
      <div class="row mt-4">
        <div class="col-md-3 feature-box">
          <img class="me-2 mb-1" src="assets/images/bandage-solid.svg" alt="Weniger Beschwerden."/>
          <span class="text" style="font-size: 20px;"><b>{{ 'landing_page.webk_features.feature4_headline' | translate }}</b></span>
          <p class="text justified-text-2">
            {{ 'landing_page.webk_features.feature4' | translate }}          
          </p>
        </div>
        <div class="col-md-3 feature-box">
          <img class="me-2 mb-1" src="assets/images/scale-balanced-solid.svg" alt="Stabiles Gewicht."/>
          <span class="text" style="font-size: 20px;"><b>{{ 'landing_page.webk_features.feature5_headline' | translate }}</b></span>
          <p class="text justified-text-2">
            {{ 'landing_page.webk_features.feature5' | translate }}          
          </p>
        </div>
        <div class="col-md-3 feature-box">
          <img class="me-2 mb-1" src="assets/images/arrow-up-right-dots-solid.svg" alt="Bessere Therapie-Voraussetzungen." />
          <span class="text" style="font-size: 20px;"><b>{{ 'landing_page.webk_features.feature6_headline' | translate }}</b></span>
          <p class="text justified-text-2">
            {{ 'landing_page.webk_features.feature6' | translate }}          
          </p>
        </div>
        <div class="col-md-3 feature-box">
          <img class="me-2 mb-1" src="assets/images/hand-fist-solid.svg" alt="Mehr Kraft und Energie." />
          <span class="text" style="font-size: 20px;"><b>{{ 'landing_page.webk_features.feature7_headline' | translate }}</b></span>
          <p class="text justified-text-2">
            {{ 'landing_page.webk_features.feature7' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div> 
  <div class="testimonials container-fluid d-flex flex-column justify-content-center align-items-center" style="background-color: #F0FAEB;">
    <div class="col-xl-5 col-10 text-center my-5">
      <h1 class="text-center text">{{ 'landing_page.testimonials.webk.headline' | translate }}</h1>
      <h4 class="text">{{ 'landing_page.testimonials.webk.text' | translate }}</h4>
    </div>
    <div class="slider-padding">
      <div class="slider d-flex" #slider>
        <div class="card" *ngFor="let slide of slidesOrder; let i = index">
          <div
            class="image-container d-flex justify-content-center align-items-center align-self-center"
          >
            <img
              class="image w-auto h-100"
              [src]="'/assets/images/testimonial-picture/' + slide + '_profile.png'"
              (error)="noImage($event)"
            />
          </div>
          <div class="linear-gradient-border position-absolute"></div>
          <h3 class="name text-center d-inline-block">
            {{ 'landing_page.testimonials.' + slide + '_headline' | translate }}
          </h3>
          <p class="description text-center align-self-center">
            {{ 'landing_page.testimonials.' + slide + '_description' | translate }}
          </p>
          <p class="text text-center px-2">
            {{ 'landing_page.testimonials.' + slide + '_text' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="navigation d-flex justify-content-center">
      <div
        class="prev-bg d-flex justify-content-center align-items-center cursor-pointer"
        (click)="prevSlide()"
      >
        <img class="prev" src="assets/images/prev_arrow.svg" />
      </div>
      <div
        class="next-bg d-flex justify-content-center align-items-center cursor-pointer"
        (click)="nextSlide()"
      >
        <img class="next" src="assets/images/next_arrow.svg" />
      </div>
    </div>
  </div>
  <div class="faq position-relative my-5">
    <div class="container-xxl">
      <h1 class="text text-center mb-2">
        {{ 'landing_page.faq_section.headline' | translate }}
      </h1>
      <div class="card">
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question1_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer1_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question2_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p
              class="answer-text"
              [innerHTML]="'landing_page.faq_section.answer2_' + selectedLandingPage | translate"
            ></p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question3_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer3_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question4_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer4_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question5_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer5_' + selectedLandingPage | translate }}
            </p>
          </div>
        </div>
        <div class="question-row">
          <div class="question d-flex justify-content-between w-100" (click)="toggleAnswer($event)">
            <h3 class="question-headline m-0 align-self-center">
              {{ 'landing_page.faq_section.question6_' + selectedLandingPage | translate }}
            </h3>
            <img class="plus-icon" src="assets/images/plus_icon.svg" />
            <img class="minus-icon hidden" src="assets/images/minus_icon.svg" />
          </div>
          <div class="answer h-auto w-100 hidden">
            <p class="answer-text">
              {{ 'landing_page.faq_section.answer6_' + selectedLandingPage | translate }}
              <a
                *ngIf="(selectedLandingPage$ | async) !== landingpage.User"
                (click)="handleSignUpMonitoringApp($event)"
              >
                {{ 'landing_page.sign_up' | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
