import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterLink, RouterOutlet } from '@angular/router';
import { DashboardComponent } from './dashboard-ui/dashboard.components';
import { LandingPageComponent } from './landing-page-ui/landing-page.component';
import { CommonModule } from '@angular/common';
import { AsyncStateOverlayModule, CustomTranslatorModule, PagesModule, SharedModule, SurveyModule} from 'ngx-esprio-shared';
import { AppAuthUIModule } from './auth-ui/app-auth-ui.module';
import { AppSurveyModule } from './survey/app-survey.module';
import { OnDemandSurveyComponent } from './survey-ui/on-demand-survey/on-demand-survey.component';

@NgModule({
  declarations: [
    DashboardComponent,
    LandingPageComponent,
    OnDemandSurveyComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    CdkTableModule,
    InlineSVGModule,
    BsDatepickerModule,
    RouterLink,
    RouterOutlet,
    CommonModule,
    CustomTranslatorModule,
    AppAuthUIModule,
    AsyncStateOverlayModule,
    AppSurveyModule,
    SurveyModule,
    SharedModule,
    PagesModule,
  ],
  providers: [],
})
export class ViewsModule {}
